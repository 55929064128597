import React from "react";
import Navbar from "../common/Navbar";
import About from "../sections/About3";
import Commitee from "../sections/Commitee";
import HomeSection from "../sections/homeSection";
import HomeSection2 from "../sections/homeSection2";
import Hub from "../sections/Hub";
import Hubs from "../sections/Hubs";
import Audience from "../sections/Audience";
import Speakers from "../sections/Speakers";
import Sponsor from "../sections/Sponsor";
import Video from "../sections/Video";
import Contact from "../sections/contactUs";
import Statistics from "../sections/Statistics";
import Plan from "../sections/Plan";
import Galary from "../sections/Galary";
import Footer from "../sections/Footer";

const HomePage = () => {
  return (
    <>
      <div className="preloader"></div>

      <span className="header-span"></span>
      <Navbar />
      {/* <HomeSection /> */}
      <HomeSection2 />
      <About />
      <Hub />
      <Hubs />
      {/* <Video /> */}
      {/* <Commitee /> */}
      {/* <Audience /> */}
      <Statistics />
      <Sponsor />
      {/* <Speakers /> */}
      <Plan />
      <Contact />
      <Galary />
      <Footer />
    </>
  );
};

export default HomePage;
