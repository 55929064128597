import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { uplaodPaper } from "../actions/research";
import FormInput from "./form-input/form-input.component";

const UplaodPaper = ({ research, clearReserch }) => {
  const { _id, researchName, author } = research;
  const dispatch = useDispatch();

  const [Paper, setPaper] = useState("");
  const [mss, setmss] = useState("");
  const [filename, setFilename] = useState(
    "تحميل الورقة كاملة / Upload the Paper"
  );

  const onChangefile = (e) => {
    setmss("");
    if (
      e.target.files[0].type === "application/pdf" ||
      e.target.files[0].type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      e.target.files[0].type === "application/msword"
    ) {
      setPaper(e.target.files[0]);
      setFilename(e.target.files[0].filename);
    } else {
      setmss("pdf او doc الملف غير مدعوم ارجو التأكد من ان يكون الملف بصيغة ");
    }
  };

  const onSubmit = () => {
    if (_id === "" || filename === "تحميل الورقة كاملة / Upload the Paper") {
      setmss("لم تتم عملية الارسال بنجاح ارجو اعادة المجاولة");
    } else {
      dispatch(uplaodPaper(Paper, _id));
      setmss("تمت عملية الارسال بنجاح");
      clearReserch();
    }
  };
  return (
    <>
      <div class="text-center pr-5 pl-5">
        <FormInput type="file" onChange={onChangefile} />
        <p className="text-center">يمكن تحميل ملف في (docx , doc) هذا الحقل</p>
        <p className="text-center text-danger">{mss}</p>
        <button className="theme-btn btn-style-one" onClick={onSubmit}>
          <span className="btn-title">ارسال </span>{" "}
        </button>
      </div>
    </>
  );
};

export default UplaodPaper;
