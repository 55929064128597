import React from "react";
import { useSelector } from "react-redux";
import { Gools1 } from "../common/sections";
const Hub = () => {
  const { lang } = useSelector((state) => state.research);
  return (
    <section id="goals" className="features-section-three no-pd-top">
      <div className="auto-container">
        <div
          className="content-box sec-title"
          style={{
            textAlign: "center",
          }}
        >
          <h1
            className="title"
            style={{ fontSize: lang === "ar" ? "3rem" : "2rem" }}
          >
            {Gools1[lang]}
          </h1>
        </div>

        <div
          className="row"
          style={{
            direction: lang !== "ar" && "ltr",
            textAlign: lang !== "ar" && "ltr",
          }}
        >
          <div className="feature-block-three col-lg-6 col-md-12 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="icon-box">
                <span className="icon flaticon-group-2"></span>
              </div>
              <div
                className="text text-justify"
                style={{ fontSize: lang === "ar" ? "1.1rem" : "1.1rem" }}
              >
                {lang === "ar" && " 1.	مواكبة الأبحاث العلمية والمساهمة في تطوير ودعم البحث العلمي."}
                {lang === "en" &&
                  "1.	Keep up with scientific research and contribute to its development."}
                {lang === "fr" &&
                  " "}
              </div>
              {/* <div className="link-box">
                <a href="about.html" className="theme-btn">
                  <span className="fa fa-angle-right"></span>
                </a>
              </div> */}
            </div>
          </div>
          <div className="feature-block-three col-lg-6 col-md-12 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="icon-box">
                <span
                  className="icon flaticon-love-planet"
                ></span>
              </div>
              <div
                className="text text-justify"
                style={{ fontSize: lang === "ar" ? "1.1rem" : "1.1rem" }}
              >
                {lang === "ar" &&
                  "2.	تبادل المعرفة والأفكار ونتائج التجارب الناجحة بما يفيد المجتمع."}
                {lang === "en" &&
                  "2.	Share knowledge, and results and exchange ideas about successful experiences for the benefit of society. "}
                {lang === "fr" &&
                  ""}
              </div>
              {/* <div className="link-box">
                <a href="about.html" className="theme-btn">
                  <span className="fa fa-angle-right"></span>
                </a>
              </div> */}
            </div>
          </div>
          <div className="feature-block-three col-lg-6 col-md-12 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="icon-box">
                <span className="icon flaticon-success"></span>
              </div>
              <div
                className="text text-justify"
                style={{ fontSize: lang === "ar" ? "1.1rem" : "1.1rem" }}
              >
                {lang === "ar" &&
                  `3.	فتح أفاق التعاون بين الباحثين في المؤسسات المحلية والدولية.`}
                {lang === "en" &&
                  `3.	Open new horizons for scientific cooperation between researchers locally and internationally. `}
                {lang === "fr" &&
                  ``}
              </div>
              {/* <div className="link-box">
                <a href="about.html" className="theme-btn">
                  <span className="fa fa-angle-right"></span>
                </a>
              </div> */}
            </div>
          </div>
          <div className="feature-block-three col-lg-6 col-md-12 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="icon-box">
                <span className="icon flaticon-desktop-computer-with-magnifying-lens-focusing-on-data"></span>
              </div>
              <div
                className="text text-justify"
                style={{ fontSize: lang === "ar" ? "1.1rem" : "1.1rem" }}
              >
                {lang === "ar" &&
                  " 4.	تفعيل دور البحث العلمي فى مواجهة تحديات البيئة والمجتمع."}
                {lang === "en" &&
                  "4. Activate the role of scientific research in facing the challenges of environment the and society."}
                {lang === "fr" &&
                  ""}
              </div>
              {/* <div className="link-box">
                <a href="about.html" className="theme-btn">
                  <span className="fa fa-angle-right"></span>
                </a>
              </div> */}
            </div>
          </div>
          {/* <div className="feature-block-three col-lg-6 col-md-12 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="icon-box">
                <span className="icon flaticon-stocks-graphic-on-laptop-monitor"></span>
              </div>
              <div
                className="text text-justify"
                style={{ fontSize: lang === "ar" ? "1.1rem" : "1.1rem" }}
              >
                {lang === "ar" &&
                  ``}
                {lang === "en" &&
                  ``}
                {lang === "fr" &&
                  ``}
              </div>
              <div className="link-box">
                <a href="about.html" className="theme-btn">
                  <span className="fa fa-angle-right"></span>
                </a>
              </div>
            </div>
          </div>
          <div className="feature-block-three col-lg-6 col-md-12 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="icon-box">
                <span className="icon flaticon-team"></span>
              </div>
              <div
                className="text text-justify"
                style={{ fontSize: lang === "ar" ? "1.1rem" : "1.1rem" }}
              >
                {lang === "ar" &&
                  ``}
                {lang === "en" &&
                  ``}
                {lang === "fr" &&
                  ``}
              </div>
              <div className="link-box">
                <a href="about.html" className="theme-btn">
                  <span className="fa fa-angle-right"></span>
                </a>
              </div>
            </div>
          </div>
          <div className="feature-block-three col-lg-6 col-md-12 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="icon-box">
                <span className="icon flaticon-love-planet"></span>
              </div>
              <div
                className="text text-justify"
                style={{ fontSize: lang === "ar" ? "1.1rem" : "1.1rem" }}
              >
                {lang === "ar" &&
                  ""}
                {lang === "en" &&
                  ""}
                {lang === "fr" &&
                  ""}
              </div>
              <div className="link-box">
                <a href="about.html" className="theme-btn">
                  <span className="fa fa-angle-right"></span>
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Hub;
