import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { about5, about4 } from "../common/sections";

const Rules = ({ lang }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <a className="theme-btn btn-style-one " onClick={handleShow}>
        <span className="btn-title">{about4[lang]}</span>
      </a>

      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {lang === "ar"
              ? "قواعد وشروط النشر بالمؤتمر"
              : "Rules and conditions for publishing conference papers"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            direction: lang === "ar" && "rtl",
            fontSize: "1rem",
            lineHeight: "2rem",
            padding: "0.5rem   2rem",
          }}
        >
          <ol
            dangerouslySetInnerHTML={{ __html: about5[lang] }}
            className={`${lang === "ar" && "text-right"} rulls`}
          ></ol>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {lang === "ar" ? "إغلاق" : "Close"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Rules;
