import React from "react";
import { useSelector } from "react-redux";
import Regester from "../common/Regester";
import Timer from "../common/timer/timer";
import { head1, head2, head3 } from "../common/sections";
const HomeSection = () => {
  const { lang } = useSelector((state) => state.research);
  return (
    <section className="banner-meetup ">
      <div
        className="bg-pattern"
        style={{ backgroundImage: "url(images/main-slider/9.jpg)" }}
      ></div>

      <div className="layer-outer">
        <div className="gradient-layer"></div>
      </div>

      <div className="auto-container">
        <div
          className="content-box"
          style={{ background: "rgba(60, 60, 60,0.4)", marginBottom: "2rem" }}
        >
          <h2>{head1[lang]}</h2>
          <h3
            style={{ color: "#fff", fontSize: lang === "ar" ? "3rem" : "2rem" }}
          >
            {head2[lang]}
          </h3>
          <br />
        </div>

        <div
          className="countdown clearfix"
          style={{ direction: lang !== "ar" && "ltr" }}
        >
          <div className="time-counter">
            <div
              className="time-countdown clearfix"
              data-countdown="2021/11/15"
            >
              <Timer time={"2021-11-15"} lang={lang} />
            </div>
          </div>
        </div>
        <div className="btn-box text-white-customer">
          {/* {Date.now() > new Date("2021-4-30") && <Regester lang={lang} />} */}
          <Regester lang={lang} />
          {/* <a
            target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLSe7OTb6ULSd9nNwfl3waLWzLF_S-VkoOdyopSS3u1oM3MbQ1A/viewform"
            className="theme-btn btn-style-three"
          >
            <span className="btn-title">
              {lang === "ar"
                ? "التسجيل للقطاع الخاص و العام"
                : "Registration for the private and public sector"}{" "}
            </span>{" "}
          </a> */}
        </div>
                {/* <div className="  bg-danger text text-center text-white bg-gradient ">
                    <h4>ملاحظة هامة: تم تأجيل فترة قبول الورقات كاملة الى غاية 23-10-2021</h4>
                </div> */}
      </div>
    </section>
  );
};

export default HomeSection;
