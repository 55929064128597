import React from "react";
import { useSelector } from "react-redux";
import {
  navlink7,
  navlink4,
  Date1,
  Date2,
  Date3,
  Date4,
  Date5,
  Date6,
  Date7,
  Date8,
  Date11,
  Date21,
  Date31,
  Date6_1,
} from "../common/sections";
const Plan = () => {
  const { lang } = useSelector((state) => state.research);
  return (
    <section id="plan" className="schedule-section">
      <div className="anim-icons">
        <span className="icon icon-circle-4 wow zoomIn"></span>
        <span className="icon icon-circle-3 wow zoomIn"></span>
      </div>

      <div className="auto-container">
        <div className="sec-title text-center">
          <span className="newtitle"></span>
          <h2>{navlink7[lang]}</h2>
        </div>

        <div className="schedule-tabs tabs-box">
          <div className="tabs-content">
            <div className="tab active-tab" id="tab-1">
              <div className="schedule-timeline">
                <div className="schedule-block">
                  <div className="inner-box">
                    <div className="inner">
                      <div className="date">
                        {lang === "ar" ? "07-01" : "01-07"}
                        <br />
                        2021
                      </div>

                      <h5 className="text-center">{Date1[lang]}</h5>
                    </div>
                  </div>
                </div>

                <div className="schedule-block even">
                  <div className="inner-box">
                    <div className="inner">
                      <div className="date">
                        {lang === "ar" ? "09-30" : "30-09"}
                        <br />
                        2021
                      </div>
                      <h5 className="text-center">{Date2[lang]}</h5>
                    </div>
                  </div>
                </div>

                <div className="schedule-block">
                  <div className="inner-box">
                    <div className="inner">
                      <div className="date">
                        {/* {lang === "ar" ? "01-06" : "06-01"}
                        {lang === "ar" ? " الي " : " to "}
                        <br /> */}
                        {lang === "ar" ? "10-14" : "14-10"}
                        <br />
                        2021
                      </div>

                      <h5 className="text-center">{Date31[lang]}</h5>
                    </div>
                  </div>
                </div>
                <div className="schedule-block even">
                  <div className="inner-box">
                    <div className="inner">
                      <div className="date">
                        {lang === "ar" ? "11-21" : "21-11"}
                        <br />
                        2021
                      </div>

                      <h5 className="text-center">{Date11[lang]}</h5>
                    </div>
                  </div>
                </div>
                {/* <div className="schedule-block ">
                  <div className="inner-box">
                    <div className="inner">
                      <div className="date">
                        {lang === "ar" ? "08-02" : "02-08"}
                        <br />
                        2021
                      </div>
                      <h5 className="text-center">{Date21[lang]}</h5>
                    </div>
                  </div>
                </div>

                <div className="schedule-block even">
                  <div className="inner-box">
                    <div className="inner">
                      <div className="date">
                        {lang === "ar" ? "10-15" : "15-10"}
                        <br />
                        2021
                      </div>
                      <h5 className="text-center">{Date3[lang]}</h5>
                    </div>
                  </div>
                </div> */}

                <div className="schedule-block ">
                  <div className="inner-box">
                    <div className="inner">
                      <div className="date">
                        {lang === "ar" ? "12-13" : "13-12"}
                        <br />
                        2021
                      </div>
                      <h5 className="text-center">{Date4[lang]}</h5>
                    </div>
                  </div>
                </div>

                <div className="schedule-block even">
                  <div className="inner-box">
                    <div className="inner">
                      <div className="date">
                        {lang === "ar" ? "12-14" : "14-12"}
                        <br />
                        2021
                      </div>
                      <h5 className="text-center">{Date5[lang]} </h5>
                    </div>
                  </div>
                </div>

                <div className="schedule-block">
                  <div className="inner-box">
                    <div className="inner">
                      <div className="date">
                      {lang === "ar" ? "12-14" : "14-12"}
                        <br />
                        2021
                      </div>
                      <h5 className="text-center">{Date6[lang]}</h5>
                    </div>
                  </div>
                </div>
                {/* <div className="schedule-block even">
                  <div className="inner-box">
                    <div className="inner">
                      <div className="date">
                        {lang === "ar" ? "09-22" : "22-09"}
                        <br />
                        2021
                      </div>
                      <h5 className="text-center">{Date6_1[lang]}</h5>
                    </div>
                  </div>
                </div> */}

                {/* <div className="schedule-block ">
                  <div className="inner-box">
                    <div className="inner">
                      <div className="date">
                        {lang === "ar" ? "09-22" : "22-09"}
                        <br />
                        2021
                      </div>
                      <h5 className="text-center">{Date8[lang]} </h5>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Plan;
