import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  head4,
  head5,
  about1,
  head3,
  about2,
  PublicMadels,
  Timing,
  Place,
  folded,
} from "../common/sections";
import Notes from "./Notes";
import Rules from "./Rules";

const About = () => {
  const { lang } = useSelector((state) => state.research);
  return (
    <section class="about-section" style={{ direction: lang !== "ar" && "ltr" }}>
           
        <div class="anim-icons full-width">
            <span class="icon icon-circle-blue wow fadeIn"></span>
            <span class="icon icon-dots wow fadeInleft"></span>
            <span class="icon icon-circle-1 wow zoomIn"></span>
        </div>
        
        <div class="auto-container">
            
           
                
               
            <div class="row">
                <div class="content-column col-lg-6 col-md-12 col-sm-12">
                    <div class="inner-column">
                        <div class="sec-title">
                            
                            <h2>{head4[lang]}</h2>
                            <div className="text text-justify">{about1[lang]}</div>
                        </div>
                        
                        <div className="btn-box text-white-customer">
                <Rules lang={lang} />

                {/* <Notes lang={lang} /> */}
               </div>
                    </div>
                </div>

                <div class="image-column col-lg-6 col-md-12 col-sm-12">
                    <div class="image-box">
                        <figure class="image wow fadeIn"><img src="images/resource/about-img-2.png" alt="" /></figure>
                    </div>
                </div>
            </div>
        </div>
    </section>

    // <section
    //   id="about"
    //   className="about-section-two"
    //   style={{ direction: lang !== "ar" && "ltr" }}
    // >
    //   <div className="anim-icons full-width">
    //     <span className="icon icon-circle-blue wow fadeIn"></span>
    //     <span className="icon icon-dots wow fadeInleft"></span>
    //     <span className="icon icon-circle-1 wow zoomIn"></span>
    //   </div>
    //   <div className="auto-container">
    //     <div className="row">
    //       <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
    //         <div
    //           className="inner-column "
    //           style={{ textAlign: lang !== "ar" && "left" }}
    //         >
    //           <div className="sec-title">
    //             <h2 className="title">{head4[lang]}</h2>
    //             <h2>{head5[lang]}</h2>
    //             <div className="text text-justify">{about1[lang]}</div>
    //           </div>
    //           <div className="row">
    //             <div className="about-block col-lg-6 col-md-6 col-sm-12">
    //               <div className="inner-box">
    //                 {/* eslint-disable-next-line */}
    //                 <a
    //                   href="https://www.google.com/maps/dir//%D9%85%D9%88%D9%82%D8%B9+%D9%81%D9%86%D8%AF%D9%82+%D9%84%D8%A7%D9%8A%D9%83%D9%88+%D8%A8%D8%AA%D9%88%D9%86%D8%B3%E2%80%AD%E2%80%AD/@36.8072058,10.1162357,12z/data=!3m1!4b1!4m9!4m8!1m1!4e2!1m5!1m1!1s0x12fd346dc6abc1bb:0xb1067db4fb2e539c!2m2!1d10.1862757!2d36.8072263"
    //                   target="_blank"
    //                 >
    //                   <h4>
    //                     <span className="icon fa fa-map-marker-alt"></span>

    //                     {Place[lang]}
    //                   </h4>
    //                 </a>
    //                 <div className="text"> {head3[lang]} </div>
    //               </div>
    //             </div>
    //             <div className="about-block col-lg-6 col-md-6 col-sm-12">
    //               <div className="inner-box">
    //                 <h4>
    //                   <span className="icon fa fa-clock"></span> {Timing[lang]}
    //                 </h4>
    //                 <div className="text">July 9, 2021 09:00 AM</div>
    //               </div>
    //             </div>
    //           </div>

    //           <div className="btn-box text-white-customer">
    //             <Rules lang={lang} />

    //             <Notes lang={lang} />
    //           </div>
    //         </div>
    //       </div>

    //       <div className="image-column col-lg-6 col-md-12 col-sm-12">
    //         <div className="image-box">
    //           <figure className="image wow fadeIn">
    //             <img src="images/resource/about-img-2.png" alt="" />
    //           </figure>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>
  );
};

export default About;
