import React from "react";

const Galary = () => {
  return (
    <section
      class="clients-section-three"
      style={{ backgroundImage: "url(images/background/9.jpg)" }}
    >
      <div class="auto-container">
        <div class="sponsors-outer">
          <div class="sponsors-carousel owl-carousel owl-theme">
            <div class="client-block">
              <figure class="image-box">
                <a href="#">
                  <img src="images/clients/2-1.png" alt="" />
                </a>
              </figure>
            </div>

            <div class="client-block">
              <figure class="image-box">
                <a href="#">
                  <img src="images/clients/2-2.png" alt="" />
                </a>
              </figure>
            </div>

            <div class="client-block">
              <figure class="image-box">
                <a href="#">
                  <img src="images/clients/2-3.png" alt="" />
                </a>
              </figure>
            </div>

            <div class="client-block">
              <figure class="image-box">
                <a href="#">
                  <img src="images/clients/2-4.png" alt="" />
                </a>
              </figure>
            </div>

            <div class="client-block">
              <figure class="image-box">
                <a href="#">
                  <img src="images/clients/2-5.png" alt="" />
                </a>
              </figure>
            </div>

            <div class="client-block">
              <figure class="image-box">
                <a href="#">
                  <img src="images/clients/2-6.png" alt="" />
                </a>
              </figure>
            </div>

            <div class="client-block">
              <figure class="image-box">
                <a href="#">
                  <img src="images/clients/2-7.png" alt="" />
                </a>
              </figure>
            </div>

            <div class="client-block">
              <figure class="image-box">
                <a href="#">
                  <img src="images/clients/2-8.png" alt="" />
                </a>
              </figure>
            </div>

            <div class="client-block">
              <figure class="image-box">
                <a href="#">
                  <img src="images/clients/2-9.png" alt="" />
                </a>
              </figure>
            </div>

            <div class="client-block">
              <figure class="image-box">
                <a href="#">
                  <img src="images/clients/2-10.png" alt="" />
                </a>
              </figure>
            </div>

            <div class="client-block">
              <figure class="image-box">
                <a href="#">
                  <img src="images/clients/2-11.png" alt="" />
                </a>
              </figure>
            </div>

            <div class="client-block">
              <figure class="image-box">
                <a href="#">
                  <img src="images/clients/2-12.png" alt="" />
                </a>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Galary;
