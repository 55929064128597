import React from "react";
import { useSelector } from "react-redux";
import { contactUs, ContactUsTitle } from "../common/sections";
const ContactUs = () => {
  const { lang } = useSelector((state) => state.research);
  return (
    <section
      class="event-info-section"
      style={{ direction: lang !== "ar" && "ltr" }}
    >
      <div class="auto-container">
        <div class="row">
          <div class="info-column col-lg-6 col-md-12 col-sm-12">
            <div class="inner-column">
              <div
                class="sec-title style-two"
                style={{ textAlign: lang !== "ar" && "left" }}
              >
                <span class="title">{ContactUsTitle[lang]}</span>
              </div>

              <div class="event-info-tabs tabs-box">
                <div class="tabs-content">
                  <div
                    style={{ textAlign: lang !== "ar" && "left" }}
                    class="tab active-tab"
                    id="tab1"
                    dangerouslySetInnerHTML={{ __html: contactUs[lang] }}
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="image-column col-lg-6 col-md-12 col-sm-12">
            <figure class="image">
              <img src="images/map-4.png" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
