import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  m,
  m1,
  m1_1,
  m2,
  m2_1,
  m3,
  m3_1,
  m4,
  m4_1,
  m5,
  m5_1,
  m6,
  m7,
  m8
  // m6_1,
} from "../common/sections";

const Hubs = () => {
  const { lang } = useSelector((state) => state.research);
  return (
    <section
      id="hubs"
      className="faq-section"
      style={{ direction: lang !== "ar" && "ltr" }}
    >
      <div className="auto-container">
        <div className="sec-title">
          <span className="newtitle text-center">{m[lang]}</span>
        </div>

        <div className="row clearfix">
          <div className="content-column col-lg-6 col-md-12 col-sm-12">
            <div className="inner-column">
              <Accordion defaultActiveKey="0" className="accordion-box">
              <Card
                  className="accordion block wow fadeInUp"
                  style={{ textAlign: lang !== "ar" && "left" }}
                >
                  <Card.Header className="acc-btn">
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey="1"
                      style={{ color: "#fff" }}
                    >
                      {/* <div className="icon-outer">
                        <span className="icon icon-plus fa fa-angle-down"></span>{" "}
                      </div> */}
                      {m1[lang]}
                    </Accordion.Toggle>
                  </Card.Header>
                  
                </Card>
                <Card
                  className="accordion block wow fadeInUp"
                  style={{ textAlign: lang !== "ar" && "left" }}
                >
                  <Card.Header className="acc-btn">
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey="1"
                      style={{ color: "#fff" }}
                    >
                      {/* <div className="icon-outer">
                        <span className="icon icon-plus fa fa-angle-down"></span>{" "}
                      </div> */}
                      {m2[lang]}
                    </Accordion.Toggle>
                  </Card.Header>
                 
                </Card>
                <Card
                  className="accordion block wow fadeInUp"
                  style={{ textAlign: lang !== "ar" && "left" }}
                >
                  <Card.Header className="acc-btn">
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey="2"
                      style={{ color: "#fff" }}
                    >
                      {/* <div className="icon-outer">
                        <span className="icon icon-plus fa fa-angle-down"></span>{" "}
                      </div> */}
                      {m3[lang]}
                    </Accordion.Toggle>
                  </Card.Header>
                  
                </Card>
                <Card
                  className="accordion block wow fadeInUp"
                  style={{ textAlign: lang !== "ar" && "left" }}
                >
                  <Card.Header className="acc-btn">
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey="5"
                      style={{ color: "#fff" }}
                    >
                      {/* <div className="icon-outer">
                        <span className="icon icon-plus fa fa-angle-down"></span>{" "}
                      </div> */}
                      {m4[lang]}
                    </Accordion.Toggle>
                  </Card.Header>
                  
                </Card>
              </Accordion>
            </div>
          </div>
          <div className="content-column col-lg-6 col-md-12 col-sm-12">
            <div className="inner-column">
              <Accordion defaultActiveKey="0" className="accordion-box">
                <Card
                  className="accordion block wow fadeInUp"
                  style={{ textAlign: lang !== "ar" && "left" }}
                >
                  <Card.Header className="acc-btn">
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey="3"
                      style={{ color: "#fff" }}
                    >
                      {/* <div className="icon-outer">
                        <span className="icon icon-plus fa fa-angle-down"></span>{" "}
                      </div> */}
                      {m5[lang]}
                    </Accordion.Toggle>
                  </Card.Header>
                 
                </Card>
                <Card
                  className="accordion block wow fadeInUp"
                  style={{ textAlign: lang !== "ar" && "left" }}
                >
                  <Card.Header className="acc-btn">
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey="5"
                      style={{ color: "#fff" }}
                    >
                      {/* <div className="icon-outer">
                        <span className="icon icon-plus fa fa-angle-down"></span>{" "}
                      </div> */}
                      {m6[lang]}
                    </Accordion.Toggle>
                  </Card.Header>
                  
                </Card>
                <Card
                  className="accordion block wow fadeInUp"
                  style={{ textAlign: lang !== "ar" && "left" }}
                >
                  <Card.Header className="acc-btn">
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey="6"
                      style={{ color: "#fff" }}
                    >
                      {/* <div className="icon-outer">
                        <span className="icon icon-plus fa fa-angle-down"></span>{" "}
                      </div> */}
                      {m7[lang]}
                    </Accordion.Toggle>
                  </Card.Header>
                  
                </Card>
                 
                <Card
                  className="accordion block wow fadeInUp"
                  style={{ textAlign: lang !== "ar" && "left" }}
                >
                  <Card.Header className="acc-btn">
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey="1"
                      style={{ color: "#fff" }}
                    >
                      {/* <div className="icon-outer">
                        <span className="icon icon-plus fa fa-angle-down"></span>{" "}
                      </div> */}
                      {m8[lang]}
                    </Accordion.Toggle>
                  </Card.Header>
                  
                </Card>
                
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hubs;
